export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    salesOrder: {},
    selectedAddressType: null,
    selectedAddress: {},
    selectedPaymentMethod: {},
    paymentMethods: [],
    resultAction: {},
  }),

  actions: {
    setSalesOrder(obj) {
      this.salesOrder = obj;
    },
    getPaymentMethods() {
      const nuxtApp = useNuxtApp()

      return nuxtApp.$api("api/front/payment-methods")
        .then(({ data }) => {
          this.paymentMethods = data;

          return data;
        })
        .catch(() => {
          console.error("[checkout] payment methods load error");
        });
    },
    setSelectedPaymentMethod(pm) {
      this.selectedPaymentMethod = pm;
    },
    setSelectedAddressType(type) {
      this.selectedAddressType = type;
    },
    setSelectedAddress(addr) {
      this.selectedAddress = addr;
    },
    checkout(data) {
      const nuxtApp = useNuxtApp()
      const $config = useRuntimeConfig().public
      return nuxtApp.$api(
        "/api/front/sales-orders/checkout",
        {
          method: 'post',
          body: {
            payment_method_id:
              typeof data.payment_method_id !== "undefined"
                ? data.payment_method_id
                : this.selectedPaymentMethod.id,
            check_terms: data.check_terms,
            address_id: data.address_id,
            address_type: data.address_type,
            discount_code: data.discount_code
          },
          headers: {
            "X-Cart": nuxtApp.$cookies.get($config.CART_COOKIE_NAME),
          },
        }
      )
        .then(({ data }) => {
          this.resultAction = data;
        }).catch((e) => {
          console.error(e);
        });;
    },
    get(code) {
      const nuxtApp = useNuxtApp()
      return nuxtApp.$api(`api/front/sales-orders/${code}`, code)
        .then(({ data }) => {
          this.salesOrder = data;
        })
        .catch(() => {
          console.error("[checkout] payment methods load error");
        });
    },
  },
});